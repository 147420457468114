import dayjs from "dayjs";

import("dayjs/locale/fr");

/** Format a date to a string in the format DD/MM/YYYY (e.g. 01/01/2024) */
export const formatDateToDDMMYYYY = (
  date: string | number | dayjs.Dayjs | Date | null | undefined
) => {
  return dayjs(date).format("DD/MM/YYYY");
};

/** Format a date to a string in the format DD month YYYY (e.g. 01 janvier 2024) */
export const formatDateToFrench = (inputDate: string) => {
  return dayjs(inputDate).locale("fr").format("DD MMMM YYYY");
};
